/**
 * webRTC config file
 *
 * @authors Luo-jinghui (luojinghui424@gmail.com)
 * @date  2020-01-17 12:04:01
 */
export const SERVER = {
  wssServer: 'wss://cloudapi.xylink.com',
  httpServer: 'https://cloudapi.xylink.com',
  logServer: 'https://log.xylink.com',
};

export const ACCOUNT = {
  extId: 'c06e7cdae4bc478cbfbceb840285f6914a6ddfea',
  clientId: 'pVhh8aLmFiAlTiqERGggxprc',
  clientSecret: 'tjrlKiwp9BBF127AnaJdsIWgwAPn90ko',
};
