var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"debug"},[_c('div',{staticClass:"debug__container"},[_c('div',{staticClass:"close-icon",on:{"click":_vm.switchDebug}}),_c('h3',[_vm._v("总览：")]),_c('table',{staticClass:"table"},[_vm._m(0),_c('tbody',[_c('tr',[_c('td',[_vm._v(_vm._s(_vm.mimeType))]),_c('td',[_vm._v(_vm._s(_vm.time || 0))]),_c('td',[_vm._v(_vm._s(_vm.bytesReceivedSecond))]),_c('td',[_vm._v(_vm._s(_vm.bytesSentSecond))])])])]),_c('br'),_c('h3',[_vm._v("发送：")]),_c('table',{staticClass:"table"},[_vm._m(1),_c('tbody',_vm._l((_vm.sender),function(item,key){return _c('tr',{key:key},[_c('td',[_vm._v("本地视频")]),_c('td',[_vm._v(_vm._s(item.frameWidth)+"*"+_vm._s(item.frameHeight))]),_c('td',[_vm._v(_vm._s(item.expBandwidth))]),_c('td',[_vm._v(_vm._s(item.bytesSentSecond))]),_c('td',[_vm._v(_vm._s(item.framesEncodedSecond))]),_c('td',[_vm._v(_vm._s(item.framesSentSecond))]),_c('td',[_vm._v(_vm._s(item.keyFramesEncoded))])])}),0)]),_c('br'),_c('h3',[_vm._v("与会者：")]),_c('table',{staticClass:"table"},[_vm._m(2),_c('tbody',_vm._l((_vm.receiver),function({
            frameWidth,
            frameHeight,
            bytesReceivedSecond,
            framesReceivedSecond,
            framesDecodedSecond,
            type,
            name,
            isContent,
            keyFramesDecoded,
          },key){return _c('tr',{key:key},[_c('td',[_vm._v(_vm._s(name))]),_c('td',[_vm._v(_vm._s(type)+" * "+_vm._s(isContent ? 'Con' : 'Peo'))]),_c('td',[_vm._v(_vm._s(frameWidth)+"*"+_vm._s(frameHeight))]),_c('td',[_vm._v(_vm._s(framesDecodedSecond))]),_c('td',[_vm._v(_vm._s(framesReceivedSecond))]),_c('td',[_vm._v(_vm._s(bytesReceivedSecond))]),_c('td',[_vm._v(_vm._s(keyFramesDecoded))])])}),0)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',{staticClass:"table-title"},[_c('th',[_vm._v("视频编码")]),_c('th',[_vm._v("时间")]),_c('th',[_vm._v("接收（kb/s）")]),_c('th',[_vm._v("发送（kb/s）")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',{staticClass:"table-title"},[_c('th',[_vm._v("类型")]),_c('th',[_vm._v("分辨率")]),_c('th',[_vm._v("期望发送（kb/s）")]),_c('th',[_vm._v("发送（kb/s）")]),_c('th',[_vm._v("编码（帧/s）")]),_c('th',[_vm._v("码率（帧/s）")]),_c('th',[_vm._v("关键帧")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',{staticClass:"table-title"},[_c('th',[_vm._v("昵称")]),_c('th',[_vm._v("类型")]),_c('th',[_vm._v("实际分辨率")]),_c('th',[_vm._v("解码（帧/s）")]),_c('th',[_vm._v("码率（帧/s）")]),_c('th',[_vm._v("接收（kb/s）")]),_c('th',[_vm._v("关键帧")])])])
}]

export { render, staticRenderFns }