var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login"},[_c('div',{staticClass:"login-container"},[_c('div',{staticClass:"login-content"},[_c('div',{staticClass:"login-title"},[_vm._v("加入会议")]),_c('el-form',{ref:"loginForm",staticClass:"login-form",attrs:{"model":_vm.loginForm,"status-icon":"","rules":_vm.rules,"label-position":_vm.labelPosition}},[(!_vm.isThird)?_c('el-form-item',{attrs:{"prop":"phone","rules":{
            required: true,
            message: '请输入小鱼账号',
            trigger: 'blur',
          }}},[_c('el-input',{attrs:{"autocomplete":"off","placeholder":"输入小鱼账号"},model:{value:(_vm.loginForm.phone),callback:function ($$v) {_vm.$set(_vm.loginForm, "phone", $$v)},expression:"loginForm.phone"}})],1):_vm._e(),(!_vm.isThird)?_c('el-form-item',{attrs:{"prop":"password","rules":{
            required: true,
            message: '请输入账号密码',
            trigger: 'blur',
          }}},[_c('el-input',{attrs:{"type":"password","autocomplete":"off","placeholder":"输入账号密码"},model:{value:(_vm.loginForm.password),callback:function ($$v) {_vm.$set(_vm.loginForm, "password", $$v)},expression:"loginForm.password"}})],1):_vm._e(),_c('el-form-item',{attrs:{"prop":"meeting"}},[_c('el-input',{attrs:{"autocomplete":"off","placeholder":"输入云会议室号或终端号"},model:{value:(_vm.loginForm.meeting),callback:function ($$v) {_vm.$set(_vm.loginForm, "meeting", $$v)},expression:"loginForm.meeting"}})],1),_c('el-form-item',{attrs:{"prop":"meetingPassword"}},[_c('el-input',{attrs:{"type":"password","autocomplete":"off","placeholder":"入会密码"},model:{value:(_vm.loginForm.meetingPassword),callback:function ($$v) {_vm.$set(_vm.loginForm, "meetingPassword", $$v)},expression:"loginForm.meetingPassword"}})],1),_c('el-button',{staticClass:"join-btn",attrs:{"type":"primary"},on:{"click":function($event){return _vm.submitForm('loginForm')}}},[_vm._v("加入会议")]),_c('el-form-item',{staticClass:"login-form-checkbox"},[_c('el-checkbox',{model:{value:(_vm.loginForm.muteVideo),callback:function ($$v) {_vm.$set(_vm.loginForm, "muteVideo", $$v)},expression:"loginForm.muteVideo"}},[_vm._v("入会时关闭摄像头")])],1),_c('el-form-item',{staticClass:"login-form-checkbox"},[_c('el-checkbox',{model:{value:(_vm.loginForm.muteAudio),callback:function ($$v) {_vm.$set(_vm.loginForm, "muteAudio", $$v)},expression:"loginForm.muteAudio"}},[_vm._v("入会时静音")])],1)],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }